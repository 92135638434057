var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-card',{attrs:{"flat":"","width":"90%","rounded":"lg"}},[_c('v-expansion-panels',{model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"py-0"},[_c('v-card-subtitle',[_vm._v("Current Queues")])],1),_c('v-expansion-panel-content',[_c('v-data-table',{staticClass:"pb-2 datatable",attrs:{"headers":_vm.currentQueueCard.headers,"items":_vm.currentQueueCard.items,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.queue_length",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"color":"black !important"},attrs:{"elevation":"0","fab":"","small":"","disabled":""}},[_vm._v(" "+_vm._s(item.queues.length)+" ")])]}},{key:"item.prefix",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"prefix",class:{ blink: _vm.lastUpdatedQueue === item.queue_name }},[_vm._v(_vm._s(item.queue_name))]),_c('v-btn',{staticClass:"create-btn",attrs:{"elevation":"0","fab":"","small":"","outlined":""},on:{"click":function($event){return _vm.$store.dispatch('initCreateTicketDialog', item.service_type_id)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)]}},{key:"item.service",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.service_type_name)+" ")]}},{key:"item.tickets",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mt-2",staticStyle:{"display":"inline"},attrs:{"icon":""},on:{"click":function($event){return _vm.scrollToLeft(item.service_type_id, 'left')}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('div',{ref:item.service_type_id,staticClass:"ticketContainer pa-2 mx-2"},[_c('div',{staticStyle:{"white-space":"nowrap"}},_vm._l((item.queues),function(ticket,index){return _c('v-tooltip',{key:index,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"ticket py-1",class:{ 'ticket-remarked': ticket.remarks },attrs:{"width":"70"},on:{"click":function($event){return _vm.selectTicket(item.queue_name, item.service_type_name, item.threshold, ticket)}}},'v-card',attrs,false),ticket.reserved_time ? on : null),[_c('v-row',{staticStyle:{"font-size":"16px"},attrs:{"no-gutters":""}},[_vm._v(_vm._s(ticket.code))]),_c('v-row',{staticClass:"timer",class:{ exceededThreshold: _vm.checkThreshold(ticket.create_datetime, item.threshold) },attrs:{"no-gutters":""}},[_vm._v(_vm._s(_vm.getTicketWaitingTime(ticket)))])],1)]}}],null,true)},[_c('span',[_vm._v("Booked at "+_vm._s(ticket.reserved_time))])])}),1)]),_c('v-btn',{staticClass:"mt-2",staticStyle:{"display":"inline"},attrs:{"icon":""},on:{"click":function($event){return _vm.scrollToLeft(item.service_type_id, 'right')}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }