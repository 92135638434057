<template>
  <div class="text-center">
    <v-dialog v-model="ticketMngtDialog.visibility" max-width="600" persistent>
      <v-card class="pa-0" flat rounded="lg">
        <!-- Header -->
        <v-toolbar dark color="rgb(206, 45, 45)" class="rounded-t-lg">
          <v-btn outlined v-if="ticketMngtDialog.page === 1" @click="printTicket"> <v-icon left>mdi-printer</v-icon>Print </v-btn>
          <v-btn icon dark v-else @click="SET_TICKET_MANAGEMENT_DIALOG_PAGE(1)">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ getTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="RESET_TICKET_MANAGEMENT_DIALOG()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <!-- Body -->
        <v-stepper v-model="ticketMngtDialog.page">
          <v-stepper-items>
            <!-- Main Ticket Operation Menu -->
            <v-stepper-content step="1" class="pa-0">
              <v-card flat class="ma-0 py-2 px-6">
                <!-- Ticket Info -->
                <v-row no-gutters class="py-1" align="center">
                  <v-col cols="4" class="text-subtitle-2">Service Type</v-col>
                  <v-col cols="8" class="text-subtitle-2">
                    <div class="textBox">{{ ticket.service_type_name }}</div>
                  </v-col>
                </v-row>
                <v-row no-gutters class="py-1" align="center">
                  <v-col cols="4" class="text-subtitle-2">Arrival Time </v-col>
                  <v-col cols="8" class="text-subtitle-2">
                    <div class="textBox">{{ $moment(this.ticket.create_datetime).format('YYYY-MM-DD HH:mm:ss') }}</div>
                  </v-col>
                </v-row>
                <v-row no-gutters class="py-1" align="center">
                  <v-col cols="4" class="text-subtitle-2">Booking Time </v-col>
                  <v-col cols="8" class="text-subtitle-2">
                    <div class="textBox">{{ ticket.reserved_time || 'N/A' }}</div>
                  </v-col>
                </v-row>
                <v-row no-gutters class="py-1" align="center">
                  <v-col cols="4" class="text-subtitle-2">Dedicated Staff</v-col>
                  <v-col cols="8" class="text-subtitle-2">
                    <div class="textBox">{{ dedicated_staff }}</div>
                  </v-col>
                </v-row>
                <v-row no-gutters class="py-1" align="center">
                  <v-col cols="4" class="text-subtitle-2">Status</v-col>
                  <v-col cols="8" class="text-subtitle-2">
                    <div class="textBox">Waiting</div>
                  </v-col>
                </v-row>
                <v-row no-gutters class="py-1 pb-4">
                  <v-col cols="4" class="text-subtitle-2">Remarks</v-col>
                  <v-col cols="8">
                    <v-textarea class="text-subtitle-2" hide-details="auto" rows="3" outlined no-resize v-model="ticket.remarks" @input="is_remark_submit_btn_show = true" />
                    <v-btn v-if="is_remark_submit_btn_show" :disabled="is_remark_submit_btn_disable" @click="$store.dispatch('updateTicketRemarks', ticket)" class="mt-2" block>Update</v-btn>
                  </v-col>
                </v-row>

                <!-- Operate Button -->
                <v-row class="mt-n2" justify="center">
                  <v-col cols="12" sm="6" class="pt-0">
                    <v-btn block large elevation="1" @click="pickAndAnnounceTicket">Pick & Announce</v-btn>
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0">
                    <v-btn block large elevation="1" @click="pickAndServeTicket">Pick & Serve</v-btn>
                  </v-col>
                </v-row>

                <v-row justify="center">
                  <v-col cols="12" sm="4" class="pt-0">
                    <v-btn block large elevation="1" @click="SET_TICKET_MANAGEMENT_DIALOG_PAGE(2)">Transfer</v-btn>
                  </v-col>
                  <v-col cols="12" sm="4" class="pt-0">
                    <v-btn block large elevation="1" @click="SET_TICKET_MANAGEMENT_DIALOG_PAGE(3)">Jump</v-btn>
                  </v-col>
                  <v-col cols="12" sm="4" class="pt-0">
                    <v-btn block large elevation="1" @click="voidTicket">Void</v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-stepper-content>

            <!-- Transfer Ticket -->
            <v-stepper-content step="2" class="pa-0">
              <transferTicketCard :ticket="ticket" />
            </v-stepper-content>

            <!-- Jump Queue -->
            <v-stepper-content step="3" class="pa-0">
              <jumpQueueCard :ticket="ticket" />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import transferTicketCard from '@/components/cards/transferTicketCard'
import jumpQueueCard from '@/components/cards/jumpQueueCard'

export default {
  name: 'ticketMngtDialog',
  components: { transferTicketCard, jumpQueueCard },
  computed: {
    ...mapState(['auth', 'general', 'ticketMngtDialog']),
    ticket() {
      return this.ticketMngtDialog.ticket
    },
    is_remark_submit_btn_show: {
      get() {
        return this.ticketMngtDialog.is_remark_submit_btn_show
      },
      set(value) {
        this.$store.commit('SET_TICKET_MANAGEMENT_DIALOG_IS_REMARK_SUBMIT_BTN_SHOW', value)
      }
    },
    is_remark_submit_btn_disable() {
      return this.ticketMngtDialog.is_remark_submit_btn_disable
    },
    getTitle() {
      if (this.ticketMngtDialog.page === 2) return 'TRANSFER'
      return `Ticket No. ${this.ticket.code}`
    },
    dedicated_staff() {
      if (this.ticket.dedicated_by_user_id) {
        const staff_info = this.general.users.find(user => user.user_id === this.ticket.dedicated_by_user_id)
        return `${staff_info.firstname} ${staff_info.lastname}`
      } else {
        return 'N/A'
      }
    }
  },
  methods: {
    ...mapMutations(['SET_TICKET_MANAGEMENT_DIALOG_PAGE', 'RESET_TICKET_MANAGEMENT_DIALOG']),
    ...mapActions(['pickTicketFromQueue']),
    pickTicket(status, is_move_ticket = false) {
      this.pickTicketFromQueue({
        pick: {
          params: {
            user_id: this.auth.user_info.user_id,
            window_id: this.general.selected_counter,
            queue_id: this.ticket.queue_name,
            ticket_user_id: this.ticket.ticket_user_id
          }
        },
        update: {
          params: {
            ticket_user_id: this.ticket.ticket_user_id,
            status: status
          }
        },
        is_move_ticket: is_move_ticket,
        move: {
          params: {
            ticket_id: this.ticket.ticket_id,
            ticket_user_id: this.ticket.ticket_user_id,
            user_id: this.auth.user_info.user_id,
            from_queue: 'X',
            to_queue: 'U'
          }
        }
      })
    },
    pickAndAnnounceTicket() {
      this.pickTicket('calling')
    },
    pickAndServeTicket() {
      this.pickTicket('serving')
    },
    // Confirmation Dialog for voiding ticket
    voidTicket() {
      this.$confirm({
        title: 'Voiding a ticket',
        message: 'You have selected to void this ticket. If this was the action you wanted to do, please confirm, or cancel the action.',
        button: { yes: 'Confirm', no: 'Cancel' },
        callback: confirm => {
          if (confirm) {
            this.pickTicket('cancelled', true)
          }
        }
      })
    },
    printTicket() {
      let routeData = this.$router.resolve({ path: `/ticket/${this.ticket.ticket_id}` })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style scoped>
.textBox {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 1px 6px 1px 6px;
  user-select: none;
}
</style>

<style>
.v-label {
  font-size: 14px;
}

.vc-text-grid {
  padding-bottom: 0;
}

.vc-title {
  margin-bottom: 10px;
}
</style>
