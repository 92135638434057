var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-card',{attrs:{"flat":"","width":"90%","rounded":"lg"}},[_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"py-0"},[_c('v-card-subtitle',[_vm._v("Handled Tickets")])],1),_c('v-expansion-panel-content',[_c('v-data-table',{staticClass:"datatable",attrs:{"headers":_vm.handledQueueCard.headers,"items":_vm.handledQueueCard.items,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.queue_length",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"color":"black !important"},attrs:{"elevation":"0","fab":"","small":"","disabled":""}},[_vm._v(" "+_vm._s(item.queues.length)+" ")])]}},{key:"item.service",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"color":"black !important"},attrs:{"elevation":"0","fab":"","small":"","disabled":""}},[_vm._v(" "+_vm._s(item.queue_name)+" ")])]}},{key:"item.service_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.queue_name === 'U' ? 'No Show / Void' : 'Completed')+" ")]}},{key:"item.tickets",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"display":"inline"},attrs:{"icon":""},on:{"click":function($event){return _vm.scrollToDirection(item.queue_name, 'left')}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('div',{ref:item.queue_name,staticClass:"ticketContainer pa-2 mx-2"},[_c('div',{staticStyle:{"white-space":"nowrap"}},_vm._l((item.queues),function(ticket){return _c('v-card',{key:ticket.ticket_id,staticClass:"ticket py-1",attrs:{"width":"70"},on:{"click":function($event){return _vm.INIT_BACK_QUEUE_DIALOG({ visibility: true, ticket: ticket, current_queue_name: item.queue_name })}}},[_c('v-row',{staticStyle:{"font-size":"16px"},attrs:{"no-gutters":"","justify":"center"}},[_vm._v(_vm._s(ticket.code))])],1)}),1)]),_c('v-btn',{staticStyle:{"display":"inline"},attrs:{"icon":""},on:{"click":function($event){return _vm.scrollToDirection(item.queue_name, 'right')}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)]}}],null,true)})],1)],1)],1)],1),_c('backQueueDialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }