<template>
  <div>
    <v-row align="center" justify="center">
      <v-card flat color="transparent" width="90%">
        <v-select
          v-model="selected_counter"
          dark
          outlined
          hide-details
          :items="general.windows"
          item-text="name_en"
          item-value="window_id"
          label="Counter"
          style="float: right; width: 15vw"
        ></v-select>
      </v-card>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-row align="center" justify="center" class="mb-5">
          <v-card flat color="transparent" width="90%">
            <v-card-actions class="px-0">
              <v-btn x-large class="text-capitalize px-5" @click="autoPick" :disabled="is_auto_pick_btn_disable" :dark="is_auto_pick_btn_disable"> Auto Pick </v-btn>
              <v-btn x-large class="text-capitalize px-5" @click="openWalkInDialog">Walk-In</v-btn>
              <v-spacer></v-spacer>
              <v-btn x-large class="text-capitalize px-5" @click="$store.dispatch('initCreateTicketDialog')">
                <v-icon left small>fas fa-plus</v-icon>
                Create Ticket
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-row>

        <v-row align="center" justify="center">
          <v-slide-x-transition mode="out-in">
            <v-card v-show="ticketPanelCard.currentTicketVisibility" flat width="90%" rounded="lg">
              <v-card-subtitle class="pb-0">Your Current Ticket</v-card-subtitle>
              <v-row class="px-4" no-gutters>
                <v-col cols="12" sm="4">
                  <v-card-text>Ticket Number: {{ ticket.code }}</v-card-text>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card-text v-if="ticket_status === 'calling'" class="text-capitalize">Status: {{ ticket_status }}({{ announce_counter }})</v-card-text>
                  <v-card-text v-else class="text-capitalize">Status: {{ ticket_status }}</v-card-text>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-card-text>
                    Timer:
                    <Timer :length="3600000 * 24" ref="timer" type="stopwatch" v-slot="{ time }">
                      <div style="display: inline-block">
                        <span>
                          <span>{{ time.h }}:</span>
                          <span>{{ time.m }}:</span>
                          <span>{{ time.s }}</span>
                        </span>
                      </div>
                    </Timer>
                  </v-card-text>
                </v-col>
              </v-row>
              <v-row class="px-4" no-gutters>
                <v-col cols="12">
                  <v-card-text class="pt-0">Service: {{ ticket.service_type_name }}</v-card-text>
                </v-col>
              </v-row>
              <v-row class="px-4 pb-4" no-gutters>
                <v-col cols="3">
                  <v-btn elevation="0" class="px-4 mt-2" @click="complete">Complete</v-btn>
                </v-col>
                <v-col cols="9">
                  <v-row no-gutters justify="end">
                    <v-btn depressed class="mr-2 mt-2" @click="announce" :disabled="ticket_status !== 'calling'">Announce</v-btn>
                    <v-btn depressed class="mr-2 mt-2" @click="markNoShow" :disabled="ticket_status === 'serving'">No Show</v-btn>
                    <v-btn depressed class="mr-2 mt-2" @click="transferTicket">Transfer</v-btn>
                    <v-btn depressed class="mr-2 mt-2" @click="completeAndTransferTicket">Complete & Transfer</v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-slide-x-transition>
        </v-row>
      </v-col>
    </v-row>

    <!-- Walk-In Dialog -->
    <walkInDialog :visibility.sync="walkInDialogShow" />

    <!-- New Ticket Dialog -->
    <createTicketDialog />

    <!-- Transfer Queue Dialog -->
    <div class="text-center">
      <v-dialog v-model="transferDialogShow" max-width="500" persistent>
        <v-card class="pa-0" flat rounded="lg">
          <!-- Header -->
          <v-toolbar dark color="rgb(206, 45, 45)" class="rounded-t-lg">
            <v-btn icon disabled> </v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-title>Transfer</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="transferDialogShow = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <transferTicketCard :ticket="ticket" from="ticketPanelCard" :visibility.sync="transferDialogShow" />
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { TimerVue as Timer } from '@josephuspaye/timer'
import transferTicketCard from '@/components/cards/transferTicketCard'
import walkInDialog from '@/components/dialogs/walkInDialog'
import createTicketDialog from '@/components/dialogs/createTicketDialog'

export default {
  name: 'ticketPanelCard',
  components: { Timer, transferTicketCard, walkInDialog, createTicketDialog },
  data() {
    return {
      visibility: false,
      transferDialogShow: false,
      announce_counter: 1
    }
  },
  computed: {
    ...mapState(['auth', 'general', 'ticketPanelCard', 'walkInDialog', 'createTicketDialog']),
    ticket() {
      return this.ticketPanelCard.ticket
    },
    ticket_status() {
      return this.ticketPanelCard.status
    },
    is_auto_pick_btn_disable() {
      return this.ticketPanelCard.is_auto_pick_btn_disable
    },
    selected_counter: {
      get() {
        return this.general.selected_counter
      },
      set(value) {
        this.SET_SELECTED_COUNTER(value)
      }
    },
    walkInDialogShow: {
      get() {
        return this.walkInDialog.visibility
      },
      set(value) {
        this.SET_WALK_IN_DIALOG_VISIBILITY(value)
      }
    }
  },
  watch: {
    'ticketPanelCard.currentTicketVisibility': {
      immediate: true,
      handler() {
        // Init the panel once the visibility of the panel changed
        if (this.ticketPanelCard.currentTicketVisibility) {
          this.announce_counter = 1
          this.$nextTick(() => {
            this.$refs.timer.reset()
            this.$refs.timer.start()
          })
        }
      }
    }
  },
  methods: {
    ...mapMutations(['SET_SNACKBAR', 'SET_WALK_IN_DIALOG_VISIBILITY', 'SET_SELECTED_COUNTER', 'RESET_TICKET_PANEL_CARD']),
    ...mapActions(['autoPickTicketFromQueue', 'completeTicket', 'announceTicket', 'updateTicketStatus', 'moveTicketPosition']),
    handleTicketPreChecking() {
      // Check whether the user currently handling any ticket
      if (!Object.keys(this.ticketPanelCard.ticket).length) {
        // Check whether the user selected the counter
        if (this.general.selected_counter === null)
          this.SET_SNACKBAR({ status: true, color: 'error', text: 'Please select the counter on the upper right corner first', icon: 'close', timeout: '2000' })
        else return true
      } else {
        this.SET_SNACKBAR({ status: true, color: 'error', text: 'Please finish your current ticket before trying to handle new ticket.', icon: 'close', timeout: '2000' })
      }
      return false
    },
    autoPick() {
      if (this.handleTicketPreChecking()) {
        this.autoPickTicketFromQueue({
          params: {
            user_id: this.auth.user_info.user_id,
            window_id: this.general.selected_counter
          }
        })
      }
    },
    openWalkInDialog() {
      this.walkInDialogShow = this.handleTicketPreChecking()
    },
    complete() {
      this.completeTicket({
        params: {
          ticket: this.ticket,
          user_id: this.auth.user_info.user_id,
          ticket_user_id: this.ticket.ticket_user_id,
          status: 'completed'
        }
      })
    },
    transferTicket() {
      this.transferDialogShow = true
    },
    completeAndTransferTicket() {
      this.transferDialogShow = true
    },
    announce() {
      this.announce_counter++
      // Update the status of the ticket
      this.announceTicket({
        params: {
          ticket_user_id: this.ticket.ticket_user_id,
          status: 'calling'
        }
      })
    },
    markNoShow() {
      this.$confirm({
        title: 'No Show',
        message: 'You have selected to mark this ticket as a no show ticket. If this was the action you wanted to do, please confirm.',
        button: { yes: 'Confirm', no: 'Cancel' },
        callback: confirm => {
          if (confirm) {
            // Update the status of the ticket
            this.updateTicketStatus({
              params: {
                ticket_user_id: this.ticket.ticket_user_id,
                status: 'missed'
              }
            })

            // Move ticket to missed queue
            this.moveTicketPosition({
              params: {
                ticket_id: this.ticket.ticket_id,
                ticket_user_id: this.ticket.ticket_user_id,
                user_id: this.auth.user_info.user_id,
                from_queue: 'X',
                to_queue: 'U'
              }
            })

            // Hide the panel
            this.RESET_TICKET_PANEL_CARD()
          }
        }
      })
    }
  }
}
</script>
