<template>
  <v-card flat class="ma-0 py-2 px-6">
    <v-row no-gutters class="py-1" align="center">
      <v-col cols="3" class="text-subtitle-2">Ticket No</v-col>
      <v-col cols="4" class="text-subtitle-2">
        <div class="textBox">{{ ticket.code }}</div>
      </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <v-col cols="3" class="text-subtitle-2">Transfer to type</v-col>
      <v-col cols="4" class="text-subtitle-2">
        <div class="textBox">{{ ticket.service_type_name }}</div>
      </v-col>
      <v-col cols="5" class="text-subtitle-2 px-5">
        <v-select class="pt-1" dense label="Change to" v-model="service_type_id" :items="service_types" item-text="service_type" item-value="service_type_id"></v-select>
      </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <v-col cols="3" class="text-subtitle-2">Dedicated Staff</v-col>
      <v-col cols="4" class="text-subtitle-2">
        <div class="textBox">{{ dedicated_staff }}</div>
      </v-col>
      <v-col cols="5" class="text-subtitle-2 px-5">
        <v-select class="pt-1" dense label="Change to" v-model="dedicated_by_user_id" :items="users" :item-text="fullname" item-value="user_id"></v-select>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="3" class="text-subtitle-2">Remarks</v-col>
      <v-col cols="4" class="text-subtitle-2">
        <v-textarea class="text-subtitle-2" rows="3" outlined value="123" disabled no-resize v-model="ticket.remarks"></v-textarea>
      </v-col>
      <v-col cols="5" class="text-subtitle-2 px-5">
        <v-textarea class="text-subtitle-2" v-model="remarks" rows="3" outlined label="Remarks" value=" " no-resize></v-textarea>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-n5 pr-5">
      <v-col cols="3" class="text-subtitle-2"> Position after Transfer</v-col>
      <v-col cols="9">
        <v-row no-gutters>
          <v-col cols="5">
            <v-switch color="#ce2d2e" v-model="auto_position" hide-details label="Auto-Assign" dense class="ml-1 mt-2"></v-switch>
          </v-col>
          <v-col cols="7">
            <v-select v-model="position" :disabled="auto_position" class="pt-1" outlined dense label="Specific Position" :items="position_list"></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Action button -->
    <v-row no-gutters class="pb-2">
      <v-col cols="6" class="px-2">
        <v-btn block :loading="submitBtnLoading" @click="submit"> <v-icon left small>mdi-upload</v-icon>Submit </v-btn>
      </v-col>
      <v-col cols="6" class="px-2">
        <v-btn block :disabled="resetBtnDisable" @click="reset"> <v-icon left small>mdi-reload</v-icon>Reset </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  name: 'transferTicketCard',
  data() {
    return {
      ticket_id: this.ticket.ticket_id,
      service_type_id: null,
      dedicated_by_user_id: null,
      remarks: null,
      position: null,
      position_list: [],
      submitBtnLoading: false,
      resetBtnDisable: false,
      auto_position: true
    }
  },
  props: {
    ticket: {
      required: true,
      type: Object
    },
    from: {
      default: 'currentQueueCard',
      type: String
    },
    visibility: {
      type: Boolean
    }
  },
  watch: {
    // Reset the entire component if the ticket prop become empty
    ticket: {
      immediate: true,
      handler() {
        if (!Object.keys(this.ticket).length) {
          this.service_type_id = null
          this.dedicated_by_user_id = null
          this.remarks = null
          this.position = null
          this.position_list = []
          this.submitBtnLoading = false
          this.resetBtnDisable = false
          this.auto_position = true
        } else {
          this.remarks = this.ticket.remarks
        }
      }
    },
    // Update the position list when the selected service type change
    async service_type_id() {
      if (this.service_type_id) {
        const service_type_info = this.general.service_types.find(service_type => service_type.service_type_id === this.service_type_id)
        const queue_info = await this.$api.getSpecificQueue(service_type_info.prefix)
        if (queue_info.data.success) {
          const queue_length = queue_info.data.data.queues.length + 1
          this.position_list = Array.from({ length: queue_length }, (_, i) => i + 1)
        }
      } else {
        this.position = null
        this.position_list = []
      }
    }
  },
  computed: {
    ...mapState(['auth', 'general', 'ticketMngtDialog']),
    // User list
    users() {
      return this.general.users
    },
    // Service type list
    service_types() {
      return this.general.service_types
    },
    dedicated_staff() {
      if (this.ticket.dedicated_by_user_id) {
        const staff_info = this.users.find(user => user.user_id === this.ticket.dedicated_by_user_id)
        return `${staff_info.firstname} ${staff_info.lastname}`
      } else {
        return 'N/A'
      }
    },
    service_type_prefix() {
      if (this.service_type_id) {
        const service_info = this.general.service_types.find(service_type => service_type.service_type_id === this.service_type_id)
        return service_info.prefix
      } else {
        return null
      }
    }
  },
  methods: {
    ...mapMutations(['SET_SNACKBAR', 'RESET_TICKET_MANAGEMENT_DIALOG', 'RESET_TICKET_PANEL_CARD']),
    ...mapActions(['updateTicketStatus', 'moveTicketPosition', 'completeTicket']),
    fullname: item => item.firstname + ' ' + item.lastname,
    submit() {
      if ((this.service_type_id && this.position) || (this.service_type_id && this.auto_position)) {
        this.submitBtnLoading = true
        this.resetBtnDisable = true
        this.completeAndTransfer()
      } else {
        this.SET_SNACKBAR({ status: true, color: 'error', text: 'Please fill all fields before submission', icon: 'close', timeout: '2000' })
      }
    },
    updateTicketRemarks() {
      try {
        // Update Ticket remarks if changed
        if (this.ticket.remarks !== this.remarks) {
          this.$api.updateTicket({
            params: {
              ticket_id: this.ticket.ticket_id,
              remarks: this.remarks
            }
          })
        }
      } catch (error) {
        this.SET_SNACKBAR({ status: true, color: 'error', text: 'Server Occur Error', icon: 'close', timeout: '2000' })
      }
    },
    async transferTicket() {
      try {
        this.updateTicketRemarks()

        const response = await this.$api.updateTicketUser({
          params: {
            ticket_user_id: this.ticket.ticket_user_id,
            service_type_id: this.service_type_id,
            dedicated_by_user_id: this.dedicated_by_user_id,
            handled_by_user_id: null,
            window_id: null
          }
        })

        if (response.data.success) {
          const queue = this.service_types.find(service_type => service_type.service_type_id === this.service_type_id).prefix
          let data = {
            params: {
              ticket_id: this.ticket.ticket_id,
              ticket_user_id: this.ticket.ticket_user_id,
              user_id: this.auth.user_info.user_id,
              from_queue: this.from === 'currentQueueCard' ? this.ticket.queue_name : 'X',
              to_queue: queue,
              action: 'transfer'
            }
          }

          if (this.position) {
            data.params.to_position = this.position
          }

          this.moveTicketPosition(data)

          this.$emit('update:visibility', false)
          this.RESET_TICKET_MANAGEMENT_DIALOG()
          this.RESET_TICKET_PANEL_CARD()
        } else {
          this.SET_SNACKBAR({ status: true, color: 'error', text: 'Ticket cannot be transfer', icon: 'close', timeout: '2000' })
        }
      } catch (error) {
        this.SET_SNACKBAR({ status: true, color: 'error', text: 'Server Occur Error', icon: 'close', timeout: '2000' })
      }
    },
    async completeAndTransfer() {
      try {
        this.updateTicketRemarks()

        // Transfer the ticket (create new ticket life-cycle)
        const response = await this.$api.assignTicket({
          params: {
            service_type_id: this.service_type_id,
            ticket_id: this.ticket.ticket_id,
            dedicated_by_user_id: this.dedicated_by_user_id || null,
            auto_assign_position: this.auto_position,
            position: this.position
          }
        })
        if (response.data.success) {
          // Update the status of the current ticket
          if (this.from === 'currentQueueCard')
            this.updateTicketStatus({
              params: {
                ticket_user_id: this.ticket.ticket_user_id,
                status: 'picked',
                user_id: this.auth.user_info.user_id
              }
            })

          this.completeTicket({
            action: 'transfer',
            params: {
              ticket: this.ticket,
              user_id: this.auth.user_info.user_id,
              ticket_user_id: this.ticket.ticket_user_id,
              status: 'completed'
            }
          })

          // Move current ticket to completed queue
          let params = {
            ticket_id: this.ticket.ticket_id,
            ticket_user_id: this.ticket.ticket_user_id,
            user_id: this.auth.user_info.user_id,
            from_queue: this.from === 'currentQueueCard' ? this.ticket.queue_name : 'X',
            action: 'transfer',
            to_queue: 'T',
            transfer_from: this.ticket.queue_name,
            transfer_to: this.service_type_prefix
          }

          this.moveTicketPosition({ params: params })
          this.$emit('update:visibility', false)
          this.RESET_TICKET_MANAGEMENT_DIALOG()
        } else {
          this.SET_SNACKBAR({ status: true, color: 'error', text: 'Ticket cannot be transfer', icon: 'close', timeout: '2000' })
        }
      } catch (error) {
        this.SET_SNACKBAR({ status: true, color: 'error', text: 'Server Occur Error', icon: 'close', timeout: '2000' })
      }
    },
    reset() {
      this.service_type_id = null
      this.dedicated_by_user_id = null
      this.remarks = null
      this.position = null
    }
  }
}
</script>

<style scoped>
.textBox {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 1px 6px 1px 6px;
  user-select: none;
}
</style>

<style>
textarea {
  color: black !important;
}
</style>
