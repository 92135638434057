<template>
  <div class="text-center">
    <v-dialog v-model="visibility" max-width="500" persistent>
      <v-card class="pa-0" flat rounded="lg">
        <!-- Header -->
        <v-toolbar dark color="rgb(206, 45, 45)" class="rounded-t-lg">
          <v-btn icon disabled></v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>Walk-in</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card flat class="ma-0 py-2 px-6">
          <!-- Ticket Info -->
          <v-row no-gutters class="py-1" align="center">
            <v-col cols="3" class="text-subtitle-2">Service Type</v-col>
            <v-col cols="9" class="text-subtitle-2">
              <v-select
                outlined
                class="pt-1"
                hide-details
                dense
                label="Select..."
                v-model="service_type_id"
                :items="general.user_skill_set"
                item-text="service_type"
                item-value="service_type_id"
              ></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters class="py-1">
            <v-col cols="3" class="text-subtitle-2">Remarks</v-col>
            <v-col cols="9">
              <v-textarea v-model="remarks" dense class="text-subtitle-2" rows="3" outlined value="" no-resize></v-textarea>
            </v-col>
          </v-row>

          <!-- Action Button -->
          <v-row no-gutters class="pb-4 mt-n2">
            <v-col cols="12">
              <v-btn block :disabled="createBtnDisable" @click="createTicket">Create </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  name: 'walkInDialog',
  props: {
    visibility: {
      required: true,
      type: Boolean
    }
  },
  data() {
    return {
      createBtnDisable: false,
      service_type_id: null,
      remarks: null
    }
  },
  computed: { ...mapState(['auth', 'general']) },
  methods: {
    ...mapMutations(['SET_SNACKBAR']),
    ...mapActions(['createWalkInTicket']),
    closeDialog() {
      // Reset the dialog after closed
      this.$emit('update:visibility', false)
      this.createBtnDisable = false
      this.service_type_id = null
      this.remarks = null
    },
    createTicket() {
      if (this.service_type_id) {
        this.createWalkInTicket({
          params: {
            ticket_type: 'walkin',
            remarks: this.remarks,
            service_type_id: this.service_type_id,
            create_by_user_id: this.auth.user_info.user_id,
            dedicated_by_user_id: this.auth.user_info.user_id,
            window_id: this.general.selected_counter,
            language: 'Cantonese'
          }
        })
      } else {
        this.SET_SNACKBAR({ status: true, color: 'error', text: 'Please fill all fields before submission', icon: 'close', timeout: '2000' })
      }
    }
  }
}
</script>

<style scoped>
.textBox {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 1px 6px 1px 6px;
  user-select: none;
}
</style>
