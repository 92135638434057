<template>
  <v-row align="center" justify="center">
    <v-card flat width="90%" rounded="lg">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header class="py-0">
            <v-card-subtitle>Handled Tickets</v-card-subtitle>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table :headers="handledQueueCard.headers" :items="handledQueueCard.items" hide-default-footer class="datatable">
              <template v-slot:[`item.queue_length`]="{ item }">
                <v-btn elevation="0" fab small disabled style="color: black !important">
                  {{ item.queues.length }}
                </v-btn>
              </template>

              <template v-slot:[`item.service`]="{ item }">
                <v-btn elevation="0" fab small disabled style="color: black !important">
                  {{ item.queue_name }}
                </v-btn>
              </template>

              <template v-slot:[`item.service_name`]="{ item }">
                {{ item.queue_name === 'U' ? 'No Show / Void' : 'Completed' }}
              </template>

              <template v-slot:[`item.tickets`]="{ item }">
                <v-btn icon style="display: inline" @click="scrollToDirection(item.queue_name, 'left')"><v-icon>mdi-chevron-left</v-icon></v-btn>
                <div class="ticketContainer pa-2 mx-2" :ref="item.queue_name">
                  <div style="white-space: nowrap">
                    <v-card
                      @click="INIT_BACK_QUEUE_DIALOG({ visibility: true, ticket: ticket, current_queue_name: item.queue_name })"
                      class="ticket py-1"
                      v-for="ticket in item.queues"
                      :key="ticket.ticket_id"
                      width="70"
                    >
                      <v-row no-gutters justify="center" style="font-size: 16px">{{ ticket.code }}</v-row>
                    </v-card>
                  </div>
                </div>
                <v-btn icon style="display: inline" @click="scrollToDirection(item.queue_name, 'right')"><v-icon>mdi-chevron-right</v-icon></v-btn>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
    <backQueueDialog />
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import backQueueDialog from '@/components/dialogs/backQueueDialog'

export default {
  name: 'handledQueueCard',
  components: { backQueueDialog },
  computed: {
    ...mapState(['handledQueueCard'])
  },
  methods: {
    ...mapMutations(['INIT_BACK_QUEUE_DIALOG']),
    scrollToDirection(ref, direction) {
      let scrollAmount = 0
      const slideTimer = setInterval(() => {
        if (direction == 'left') {
          this.$refs[ref].scrollLeft -= 10
        } else {
          this.$refs[ref].scrollLeft += 10
        }
        scrollAmount += 10
        if (scrollAmount >= 100) {
          window.clearInterval(slideTimer)
        }
      }, 15)
    }
  }
}
</script>

<style scoped>
.ticketContainer {
  overflow-x: scroll;
  width: 48vw;
  display: inline-flex;
}

.ticketContainer::-webkit-scrollbar {
  display: none;
}

.ticket {
  cursor: pointer;
  display: inline-block;
  margin-right: 15px;
}

@media only screen and (min-width: 500px) and (max-width: 600px) {
  .ticketContainer {
    width: 40vw;
  }
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
  .ticketContainer {
    width: 15vw;
  }
}

@media screen and (min-width: 767) and (max-width: 1023px) {
  .ticketContainer {
    width: 5vw;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .ticketContainer {
    width: 35vw;
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1275px) {
  .ticketContainer {
    width: 25vw;
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1599px) {
  .ticketContainer {
    width: 30vw;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1919px) {
  .ticketContainer {
    width: 40vw;
  }
}
</style>
