<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12">
        <ticketPanelCard />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <currentQueueCard />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <handledQueueCard />
      </v-col>
    </v-row>
    <ticketMngtDialog />
  </v-container>
</template>

<script>
// Cards
import ticketPanelCard from '@/components/cards/ticketPanelCard'
import currentQueueCard from '@/components/cards/currentQueueCard'
import handledQueueCard from '@/components/cards/handledQueueCard'

// Dialogs
import ticketMngtDialog from '@/components/dialogs/ticketMngtDialog'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Home',
  components: { ticketPanelCard, currentQueueCard, handledQueueCard, ticketMngtDialog },
  computed: { ...mapState(['auth']) },
  methods: { ...mapActions(['socket_update_queue', 'checkHandlingTicket']) },
  mounted() {
    // Regularly update the queue list (1mins)
    setInterval(() => {
      this.socket_update_queue()
    }, 60000)

    // Check any unhandled ticket is belong to current user
    this.checkHandlingTicket({
      user_id: this.auth.user_info.user_id
    })
  }
}
</script>
