<template>
  <v-row align="center" justify="center">
    <v-card flat width="90%" rounded="lg">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel>
          <v-expansion-panel-header class="py-0">
            <v-card-subtitle>Current Queues</v-card-subtitle>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table :headers="currentQueueCard.headers" :items="currentQueueCard.items" hide-default-footer class="pb-2 datatable">
              <template v-slot:[`item.queue_length`]="{ item }">
                <v-btn elevation="0" fab small disabled style="color: black !important">
                  {{ item.queues.length }}
                </v-btn>
              </template>
              <template v-slot:[`item.prefix`]="{ item }">
                <div class="prefix" :class="{ blink: lastUpdatedQueue === item.queue_name }">{{ item.queue_name }}</div>
                <v-btn @click="$store.dispatch('initCreateTicketDialog', item.service_type_id)" class="create-btn" elevation="0" fab small outlined><v-icon>mdi-plus</v-icon></v-btn>
              </template>
              <template v-slot:[`item.service`]="{ item }">
                {{ item.service_type_name }}
              </template>
              <template v-slot:[`item.tickets`]="{ item }">
                <v-btn icon style="display: inline" class="mt-2" @click="scrollToLeft(item.service_type_id, 'left')"><v-icon>mdi-chevron-left</v-icon></v-btn>
                <div class="ticketContainer pa-2 mx-2" :ref="item.service_type_id">
                  <div style="white-space: nowrap">
                    <v-tooltip top v-for="(ticket, index) in item.queues" :key="index">
                      <template v-slot:activator="{ on, attrs }">
                        <v-card
                          v-bind="attrs"
                          v-on="ticket.reserved_time ? on : null"
                          @click="selectTicket(item.queue_name, item.service_type_name, item.threshold, ticket)"
                          class="ticket py-1"
                          :class="{ 'ticket-remarked': ticket.remarks }"
                          width="70"
                        >
                          <v-row no-gutters style="font-size: 16px">{{ ticket.code }}</v-row>
                          <v-row no-gutters class="timer" :class="{ exceededThreshold: checkThreshold(ticket.create_datetime, item.threshold) }">{{ getTicketWaitingTime(ticket) }}</v-row>
                        </v-card>
                      </template>
                      <span>Booked at {{ ticket.reserved_time }}</span>
                    </v-tooltip>
                  </div>
                </div>
                <v-btn icon style="display: inline" class="mt-2" @click="scrollToLeft(item.service_type_id, 'right')"><v-icon>mdi-chevron-right</v-icon></v-btn>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'currentQueueCard',
  data() {
    return {
      panel: 0,
      lastUpdatedQueue: null,
      blinking_timer: null
    }
  },
  computed: { ...mapState(['general', 'currentQueueCard', 'ticketPanelCard']) },
  watch: {
    'currentQueueCard.items'(new_queue, old_queue) {
      if (old_queue.length) {
        const updated_queue = new_queue.find((queue, index) => queue.queues.length !== old_queue[index].queues.length)
        if (updated_queue) {
          const updated_queue_id = updated_queue.service_type_id
          const old_queue_length = old_queue.find(queue => queue.service_type_id === updated_queue_id)?.queues?.length

          if (old_queue_length < updated_queue.queues.length) {
            this.lastUpdatedQueue = updated_queue.queue_name
            clearTimeout(this.blinking_timer)
            this.blinking_timer = setTimeout(() => (this.lastUpdatedQueue = null), 6000)
          }
        }
      }
    }
  },
  methods: {
    ...mapMutations(['SET_SNACKBAR', 'INIT_TICKET_MANAGEMENT_DIALOG']),
    // Calculating the how long the ticket has been waited
    getTicketWaitingTime(ticket) {
      return parseInt((new Date().getTime() - new Date(ticket.create_datetime).getTime()) / 1000 / 60)
    },
    // Monitoring the waiting time of the ticket whether exceed the threshold
    checkThreshold(create_datetime, threshold) {
      const waitingTime = parseInt((new Date().getTime() - new Date(create_datetime).getTime()) / 1000 / 60)
      return waitingTime >= threshold
    },
    // Trigger when user select any one of the tickets on the list
    selectTicket(queue_name, service_type_name, threshold, ticket) {
      // Check whether the user currently handling any ticket
      if (!Object.keys(this.ticketPanelCard.ticket).length) {
        // Check whether the user selected the counter
        if (this.general.selected_counter === null)
          this.SET_SNACKBAR({ status: true, color: 'error', text: 'Please select the counter on the upper right corner first', icon: 'close', timeout: '2000' })
        else {
          // Adding queue info to each ticket for later use
          ticket.queue_name = queue_name
          ticket.service_type_name = service_type_name
          ticket.threshold = threshold

          // Show the ticket management dialog
          this.INIT_TICKET_MANAGEMENT_DIALOG({ visibility: true, ticket: ticket })
        }
      } else {
        this.SET_SNACKBAR({ status: true, color: 'error', text: 'Please finish your current ticket before trying to handle other tickets.', icon: 'close', timeout: '2000' })
      }
    },
    scrollToLeft(ref, direction) {
      let scrollAmount = 0
      const slideTimer = setInterval(() => {
        if (direction == 'left') {
          this.$refs[ref].scrollLeft -= 10
        } else {
          this.$refs[ref].scrollLeft += 10
        }
        scrollAmount += 10
        if (scrollAmount >= 100) {
          window.clearInterval(slideTimer)
        }
      }, 15)
    }
  }
}
</script>

<style scoped>
.ticketContainer {
  overflow-x: scroll;
  width: 48vw;
  display: inline-flex;
}

.ticketContainer::-webkit-scrollbar {
  display: none;
}

.ticket {
  cursor: pointer;
  display: inline-block;
  margin-right: 15px;
  padding: 10px;
}

.ticket-remarked::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 10px; /* Adjust the size of the dot */
  height: 10px; /* Adjust the size of the dot */
  background-color: red;
  border-radius: 50%;
  transform: translate(50%, -50%);
}

.timer {
  font-size: 14px;
  float: right;
  margin-top: -5px;
  color: rgb(65, 179, 65);
}

.exceededThreshold {
  color: red !important;
}

.prefix {
  background-color: rgba(0, 0, 0, 0.12);
  font-size: 0.75rem;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

.create-btn {
  margin-left: 20px;
}

@keyframes blink {
  0% {
    background-color: rgba(0, 0, 0, 0.12);
  }
  50% {
    background-color: rgba(206, 45, 45, 1);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.12);
  }
}

.blink {
  color: #fff;
  animation: blink 2s linear infinite;
}

@media only screen and (min-width: 500px) and (max-width: 600px) {
  .ticketContainer {
    width: 40vw;
  }
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
  .ticketContainer {
    width: 15vw;
  }
}

@media screen and (min-width: 767) and (max-width: 1023px) {
  .ticketContainer {
    width: 5vw;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .ticketContainer {
    width: 35vw;
  }
}

@media only screen and (min-width: 1281px) and (max-width: 1275px) {
  .ticketContainer {
    width: 25vw;
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1599px) {
  .ticketContainer {
    width: 30vw;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1919px) {
  .ticketContainer {
    width: 40vw;
  }
}
</style>
