<template>
  <v-card flat class="ma-0 py-2 px-6">
    <!-- Ticket Info -->
    <v-row no-gutters class="py-1" align="center">
      <v-col cols="3" class="text-subtitle-2">Service Type</v-col>
      <v-col cols="9" class="text-subtitle-2">
        <div class="textBox">{{ ticket.service_type_name }}</div>
      </v-col>
    </v-row>
    <v-row no-gutters class="py-1" align="center">
      <v-col cols="3" class="text-subtitle-2">Arrival Time </v-col>
      <v-col cols="9" class="text-subtitle-2">
        <div class="textBox">{{ $moment(this.ticket.create_datetime).format('YYYY-MM-DD HH:mm:ss') }}</div>
      </v-col>
    </v-row>
    <v-row no-gutters class="py-1">
      <v-col cols="3" class="text-subtitle-2 mt-3"> Position after Jump</v-col>
      <v-col cols="9">
        <v-select class="pt-1" outlined dense label="Position" v-model="position" :items="position_list"></v-select>
      </v-col>
    </v-row>

    <!-- Action Button -->
    <v-row no-gutters class="pb-4">
      <v-col cols="12">
        <v-btn block @click="jumpQueue" :disabled="jumpBtnDisable"> Jump </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  name: 'jumpQueueCard',
  data() {
    return {
      jumpBtnDisable: false,
      position: null,
      position_list: []
    }
  },
  props: {
    ticket: {
      required: true
    }
  },
  computed: { ...mapState(['auth', 'general']) },
  watch: {
    ticket: {
      immediate: true,
      async handler() {
        if (Object.keys(this.ticket).length) {
          const service_type_info = this.general.service_types.find(service_type => service_type.service_type_id === this.ticket.service_type_id)
          const queue_info = await this.$api.getSpecificQueue(service_type_info.prefix)
          if (queue_info.data.success) {
            let queue_length = queue_info.data.data.queues.length
            this.positionSelectDisable = false
            this.position_list = Array.from({ length: queue_length }, (_, i) => i + 1)
          }
        } else {
          this.position = null
          this.position_list = []
          this.jumpBtnDisable = false
        }
      }
    },
    position_list() {
      // Set default jump to position 2 if the position list larger than 2
      if (this.position_list.length > 2) {
        this.position = 2
      }
    }
  },
  methods: {
    ...mapActions(['moveTicketPosition']),
    ...mapMutations(['RESET_TICKET_MANAGEMENT_DIALOG']),
    jumpQueue() {
      this.jumpBtnDisable = true
      // Move ticket to completed queue
      this.moveTicketPosition({
        params: {
          ticket_id: this.ticket.ticket_id,
          ticket_user_id: this.ticket.ticket_user_id,
          user_id: this.auth.user_info.user_id,
          from_queue: this.ticket.queue_name,
          action: 'jump',
          to_queue: this.ticket.queue_name,
          to_position: this.position
        }
      })

      this.RESET_TICKET_MANAGEMENT_DIALOG()
    }
  }
}
</script>

<style scoped>
.textBox {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 1px 6px 1px 6px;
  user-select: none;
}
</style>
