<template>
  <div class="text-center">
    <v-dialog v-model="backQueueDialog.visibility" max-width="500" persistent>
      <v-card class="pa-0" flat rounded="lg">
        <!-- Header -->
        <v-toolbar dark color="rgb(206, 45, 45)" class="rounded-t-lg">
          <v-btn icon dark>
            <v-icon>mdi-printer</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ `Ticket No. ${ticket.code}` }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="RESET_BACK_QUEUE_DIALOG()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card>

      <v-card flat class="ma-0 py-2 px-6">
        <!-- Ticket Info -->
        <v-row no-gutters class="py-1" align="center">
          <v-col cols="4" class="text-subtitle-2">Last Handled By</v-col>
          <v-col cols="8" class="text-subtitle-2">
            <div class="textBox">{{ last_handled_by_staff }}</div>
          </v-col>
        </v-row>
        <v-row no-gutters class="py-1" align="center">
          <v-col cols="4" class="text-subtitle-2">Service Type</v-col>
          <v-col cols="8" class="text-subtitle-2">
            <div class="textBox">{{ service_type }}</div>
          </v-col>
        </v-row>
        <v-row no-gutters class="py-1" align="center">
          <v-col cols="4" class="text-subtitle-2">Arrival Time </v-col>
          <v-col cols="8" class="text-subtitle-2">
            <div class="textBox">{{ $moment(this.ticket.create_datetime).format('YYYY-MM-DD HH:mm:ss') }}</div>
          </v-col>
        </v-row>
        <v-row no-gutters class="py-1" align="center">
          <v-col cols="4" class="text-subtitle-2">Serving Time </v-col>
          <v-col cols="8" class="text-subtitle-2">
            <div class="textBox">{{ this.ticket.serving_time || 'N/A' }}</div>
          </v-col>
        </v-row>
        <v-row no-gutters class="py-1" align="center">
          <v-col cols="4" class="text-subtitle-2">Completed Time </v-col>
          <v-col cols="8" class="text-subtitle-2">
            <div class="textBox">{{ ticket.completed_time || 'N/A' }}</div>
          </v-col>
        </v-row>
        <v-row no-gutters class="py-1" align="center">
          <v-col cols="4" class="text-subtitle-2">Booking Time </v-col>
          <v-col cols="8" class="text-subtitle-2">
            <div class="textBox">{{ ticket.reserved_time || 'N/A' }}</div>
          </v-col>
        </v-row>
        <v-row no-gutters class="py-1" align="center">
          <v-col cols="4" class="text-subtitle-2">Dedicated Staff</v-col>
          <v-col cols="8" class="text-subtitle-2">
            <div class="textBox">{{ dedicated_staff }}</div>
          </v-col>
        </v-row>
        <v-row no-gutters class="py-1" align="center">
          <v-col cols="4" class="text-subtitle-2">Status</v-col>
          <v-col cols="8" class="text-subtitle-2">
            <div class="textBox text-capitalize">{{ ticket_status }}</div>
          </v-col>
        </v-row>
        <v-row no-gutters class="py-1">
          <v-col cols="4" class="text-subtitle-2">Remarks</v-col>
          <v-col cols="8">
            <v-textarea v-model="ticket.remarks" class="text-subtitle-2" rows="3" outlined value="123" disabled no-resize></v-textarea>
          </v-col>
        </v-row>

        <!-- Operate Button -->
        <v-row class="mt-n2">
          <v-col cols="12" class="pt-0">
            <v-btn block large elevation="1" @click="backToQueue" :disabled="ticket_status === 'completed'">Back to Queue</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'backQueueDialog',
  data() {
    return {
      back_to_queue_id: null,
      service_type: '',
      ticket_status: ''
    }
  },
  computed: {
    ...mapState(['auth', 'general', 'backQueueDialog']),
    ticket() {
      return this.backQueueDialog.ticket
    },
    last_handled_by_staff() {
      if (this.ticket.handled_by_user_id) {
        const staff_info = this.general.users.find(user => user.user_id === this.ticket.handled_by_user_id)
        return `${staff_info.firstname} ${staff_info.lastname}`
      } else {
        return 'N/A'
      }
    },
    dedicated_staff() {
      if (this.ticket.dedicated_by_user_id) {
        const staff_info = this.general.users.find(user => user.user_id === this.ticket.dedicated_by_user_id)
        return `${staff_info.firstname} ${staff_info.lastname}`
      } else {
        return 'N/A'
      }
    }
  },
  methods: {
    ...mapMutations(['RESET_BACK_QUEUE_DIALOG']),
    ...mapActions(['backTicketToQueue']),
    backToQueue() {
      this.backTicketToQueue({
        params: {
          service_type_id: this.ticket.service_type_id,
          ticket_id: this.ticket.ticket_id,
          is_reserved_ticket: this.ticket.is_reserved_ticket,
          dedicated_by_user_id: this.ticket.dedicated_by_user_id,
          ticket_user_id: this.ticket.ticket_user_id,
          user_id: this.auth.user_info.user_id,
          from_queue: this.backQueueDialog.current_queue_name,
          to_queue: 'T', // Move to Trash
          action: 'back',
          position: 5
        }
      })
    }
  },
  watch: {
    ticket: {
      immediate: true,
      async handler() {
        if (Object.keys(this.ticket).length) {
          try {
            // Get Service Type Name and Prefix
            if (this.ticket.service_type_id) {
              const service_type_info = this.general.service_types.find(service_type => service_type.service_type_id === this.ticket.service_type_id)
              this.service_type = service_type_info.service_type
              this.back_to_queue_id = service_type_info.prefix
            } else {
              this.back_to_queue_id = null
              this.service_type = ''
            }

            // Get Ticket Status
            const response = await this.$api.getTicketStatus(this.ticket.ticket_user_id)
            if (response.data.success) {
              let status = response.data.data.status
              if (status === 'missed') status = 'No Show'
              if (status === 'cancelled') status = 'Void'
              this.ticket_status = status
            }
          } catch (error) {
            console.log(error.message)
          }
        }
      }
    }
  }
}
</script>
