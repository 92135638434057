<template>
  <div class="text-center">
    <v-dialog v-model="visibility" max-width="500" persistent>
      <v-card class="pa-0" flat rounded="lg">
        <!-- Header -->
        <v-toolbar dark color="rgb(206, 45, 45)" class="rounded-t-lg">
          <v-btn icon disabled></v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>New Ticket</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$store.commit('SET_CREATE_TICKET_DIALOG_VISIBILITY', false)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card flat class="ma-0 py-2 px-6">
          <!-- Ticket Info -->
          <v-row no-gutters class="py-1">
            <v-col cols="6" class="px-1">
              <v-select outlined hide-details dense label="Service Types..." v-model="service_type_id" :items="general.service_types" item-text="service_type" item-value="service_type_id"></v-select>
            </v-col>
            <v-col cols="6" class="px-1">
              <v-select outlined hide-details dense label="Dedicated Staffs..." v-model="dedicated_by_user_id" :items="users" :item-text="fullname" item-value="user_id"></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters class="py-1">
            <v-col cols="12" class="px-1">
              <v-select outlined hide-details dense label="Language" v-model="language" :items="language_list"></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" class="px-1 mt-1">
              <v-textarea hide-details dense rows="3" label="Remarks" v-model="remarks" outlined no-resize></v-textarea>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="6" class="mt-n3" v-if="booking_visibility">
              <v-checkbox dense hide-details label="Online Reservation" v-model="online_reserved" :disabled="createTicketDialog.is_booking_valid"></v-checkbox>
            </v-col>
          </v-row>

          <!-- Show If the "Online Reservation" checkbox is clicked -->
          <v-slide-x-reverse-transition>
            <v-row no-gutters v-if="online_reserved" class="mt-1">
              <v-col cols="6" class="px-1">
                <v-text-field outlined dense label="Tel" v-model="tel" :disabled="createTicketDialog.is_booking_valid" :error-messages="createTicketDialog.lookup_error_msg"></v-text-field>
              </v-col>
              <v-col cols="6" class="px-1">
                <v-btn depressed @click="lookUpBooking" :disabled="createTicketDialog.is_booking_valid">Look Up</v-btn>
              </v-col>
            </v-row>
          </v-slide-x-reverse-transition>

          <!-- Show If the booking record is valid -->
          <v-slide-x-reverse-transition>
            <v-row no-gutters v-if="createTicketDialog.is_booking_valid" class="mt-n4 mb-4">
              <v-col cols="12">
                <div class="text-subtitle-2">Booking Info</div>
                <v-card v-for="record in booking_records" :key="record.booking_id" class="my-3" flat outlined>
                  <v-row class="py-2" justify="center" align="center" no-gutters :class="{ 'colored-outline': booking_id === record.booking_id }">
                    <v-col cols="8">
                      <!-- Service Type -->
                      <v-row no-gutters>
                        <v-col cols="6">
                          <v-card-subtitle class="py-1">Service Type: </v-card-subtitle>
                        </v-col>
                        <v-col cols="6">
                          <v-card-text class="py-1"> {{ getServiceTypeByID(record.service_type_id) }} </v-card-text>
                        </v-col>
                      </v-row>

                      <!-- Customer Name -->
                      <v-row no-gutters>
                        <v-col cols="6">
                          <v-card-subtitle class="py-1">Customer Name: </v-card-subtitle>
                        </v-col>
                        <v-col cols="6">
                          <v-card-text class="py-1"> {{ record.lastname }} </v-card-text>
                        </v-col>
                      </v-row>

                      <!-- Tel -->
                      <v-row no-gutters>
                        <v-col cols="6">
                          <v-card-subtitle class="py-1">Phone Number: </v-card-subtitle>
                        </v-col>
                        <v-col cols="6">
                          <v-card-text class="py-1"> {{ record.tel }} </v-card-text>
                        </v-col>
                      </v-row>

                      <!-- Dedicated Staff -->
                      <v-row no-gutters>
                        <v-col cols="6">
                          <v-card-subtitle class="py-1">Dedicated Staff: </v-card-subtitle>
                        </v-col>
                        <v-col cols="6">
                          <v-card-text class="py-1"> {{ getStaffNameByID(record.dedicated_staff_id) }} </v-card-text>
                        </v-col>
                      </v-row>

                      <!-- Date -->
                      <v-row no-gutters>
                        <v-col cols="6">
                          <v-card-subtitle class="py-1">Date: </v-card-subtitle>
                        </v-col>
                        <v-col cols="6">
                          <v-card-text class="py-1"> {{ record.date }} </v-card-text>
                        </v-col>
                      </v-row>

                      <!-- Time -->
                      <v-row no-gutters>
                        <v-col cols="6">
                          <v-card-subtitle class="py-1">Time: </v-card-subtitle>
                        </v-col>
                        <v-col cols="6">
                          <v-card-text class="py-1"> {{ record.time }} </v-card-text>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="4">
                      <v-btn style="float: right" class="mr-4" @click="selectBooking(record)" :disabled="booking_id === record.booking_id">SELECT</v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-slide-x-reverse-transition>
          <!-- Action Button -->
          <v-row no-gutters class="pb-4 mt-2 px-1" :class="{ 'mt-n4': online_reserved }">
            <v-col cols="12">
              <v-btn block @click="createTicket">Create</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  name: 'createTicketDialog',
  data() {
    return {
      language_list: ['Cantonese', 'Mandarin', 'English'],
      language: 'Cantonese',
      dedicated_by_user_id: null,
      remarks: null,
      online_reserved: false,
      reserved_time: null,
      booking_id: null,
      tel: null,
      booking_visibility: false
    }
  },
  computed: {
    ...mapState(['auth', 'general', 'createTicketDialog']),
    visibility() {
      return this.createTicketDialog.visibility
    },
    service_type_id: {
      get() {
        return this.createTicketDialog.service_type_id
      },
      set(value) {
        this.$store.commit('SET_CREATE_TICKET_DIALOG_SERVICE_TYPE_ID', value)
      }
    },
    booking_records() {
      return this.createTicketDialog.booking_records
    },
    users() {
      return this.general.users
    }
  },
  methods: {
    ...mapMutations(['SET_SNACKBAR']),
    ...mapActions(['checkBooking', 'createNormalTicket']),
    fullname: item => item.firstname + ' ' + item.lastname,
    getServiceTypeByID(service_type_id) {
      const service = this.general.service_types.find(service_type => service_type.service_type_id === service_type_id)
      return service.service_type
    },
    getStaffNameByID(user_id) {
      const user = this.general.users.find(user => user.user_id === user_id)
      return user ? `${user.firstname} ${user.lastname}` : 'N/A'
    },
    lookUpBooking() {
      if (this.tel) {
        this.checkBooking({
          params: {
            tel: this.tel,
            from_date: new Date().toISOString().slice(0, 10),
            to_date: new Date().toISOString().slice(0, 10)
          }
        })
      } else {
        this.SET_SNACKBAR({ status: true, color: 'error', text: 'Please enter the TEL for checking the booking record', icon: 'close', timeout: '2000' })
      }
    },
    createTicket() {
      if (this.service_type_id) {
        if (this.online_reserved && !this.createTicketDialog.is_booking_valid) {
          this.SET_SNACKBAR({ status: true, color: 'error', text: 'Failed to created ticket since the booking information is not valid', icon: 'close', timeout: '2000' })
        } else {
          this.createNormalTicket({
            params: {
              ticket_type: 'normal',
              service_type_id: this.service_type_id,
              create_by_user_id: this.auth.user_info.user_id,
              remarks: this.remarks,
              is_reserved_ticket: this.online_reserved,
              dedicated_by_user_id: this.dedicated_by_user_id,
              reserved_time: this.reserved_time,
              language: this.language
            }
          })
        }
      } else {
        this.SET_SNACKBAR({ status: true, color: 'error', text: 'Please select a service type for the ticket', icon: 'close', timeout: '2000' })
      }
    },
    selectBooking(booking_record) {
      this.booking_id = booking_record.booking_id
      this.service_type_id = booking_record.service_type_id
      this.dedicated_by_user_id = booking_record.dedicated_staff_id
      this.reserved_time = booking_record.time
    }
  },
  watch: {
    visibility() {
      // Reset the dialog after closed
      if (!this.visibility) {
        this.service_type_id = null
        this.dedicated_by_user_id = null
        this.remarks = null
        this.online_reserved = false
        this.reserved_time = null
        this.booking_id = null
        this.tel = null
        this.language = 'Cantonese'
      }
    },
    service_type_id() {
      // If the service type is Reserved, the online reserve checkbox will auto-checked
      if (this.service_type_id === 8) {
        this.booking_visibility = true
        this.online_reserved = true
      } else {
        this.booking_visibility = false
      }
    }
  }
}
</script>

<style scoped>
.textBox {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 1px 6px 1px 6px;
  user-select: none;
}

.colored-outline {
  border: 1px solid red;
}
</style>
